<template>
  <app-list-view
    server-side
    app="document_template"
    model="industry"
    api-url="doc-template/industry/"
    :title="$t('menu.businessIndustry')"
    :createTo="{ name: 'businessIndustryCreate' }"
    :editTo="{ name: 'businessIndustryEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'businessIndustry',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('menu.businessIndustry'), value: 'name' },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.businessCategory'),
          value: 'businessCategory',
          link: {
            prepareRouter: this.businessCategoryLink
          },
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'businessIndustry'
    })
  },
  methods: {
    businessCategoryLink(item) {
      return {
        name: 'businessCategory',
        query: { industry_id: item.id }
      }
    }
  }
}
</script>
